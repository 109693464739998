import React, { useCallback, useEffect, useReducer } from 'react';
import InterstitialPage from 'components/InterstitialPage';
import useApiRequestHandler from 'helpers/useApiRequestHandler';
import useLocalAddonSelection from 'helpers/useLocalAddonSelection';
import { CsMeta } from 'types/contentStack';

type CheckDetailsLoadingProps = {
  checkDetailsLoadingContent: {
    meta: CsMeta;
    heading: string;
    subheading: string;
  };
  tier?: string | null;
  moveNext: () => void;
};

const CheckDetailsLoadingPage: React.FC<CheckDetailsLoadingProps> = ({
  checkDetailsLoadingContent: { heading, subheading, meta },
  tier,
  moveNext,
}) => {
  const [requestStarted, setRequestStarted] = useReducer(() => true, false);

  const { requestHandler } = useApiRequestHandler();
  const { commitLocalAddonSelection } = useLocalAddonSelection(requestHandler);

  const commitLocalAddonsAndMoveNext = useCallback(async (): Promise<void> => {
    const updatedQuote = await commitLocalAddonSelection();
    if (updatedQuote) {
      moveNext();
    }
  }, [commitLocalAddonSelection, moveNext]);

  useEffect(() => {
    if (!requestStarted) {
      setRequestStarted();
      commitLocalAddonsAndMoveNext();
    }
  }, [commitLocalAddonsAndMoveNext, requestStarted]);

  return (
    <InterstitialPage
      heading={heading}
      subheading={subheading}
      meta={meta}
      tier={tier}
      data-cy="CheckDetailsLoadingInterstitialPage"
    />
  );
};

export default CheckDetailsLoadingPage;
