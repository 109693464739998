import quoteClient, { UpdateTierRequest } from 'api/quoteClient';
import renewalClient from 'api/renewalClient';
import { useDispatch, useSelector } from 'react-redux';
import {
  CoverageSelection,
  getLatestBreakdownSelection,
  isLocalBreakdownValid,
  LocalBreakdown,
  useAddonSelection,
} from 'state/addonsSelection/addonsSelection';
import { RootState } from 'state/createStore';
import {
  BreakdownCoverLevel,
  isRenewal,
  Quote,
  Renewal,
  TierOptions,
  UPDATE_QUOTE,
  UPDATE_RENEWAL,
} from 'state/quote/quote';
import { RequestHandlerType } from './useApiRequestHandler';

type LocalAddonSelectionHelper = {
  updateLocalCoverage: (addon: CoverageSelection) => void;
  updateLocalBreakdown: (
    breakdownSelected: boolean | null,
    coverLevel: BreakdownCoverLevel | undefined
  ) => void;
  updateLocalTier: (tier: TierOptions) => void;
  commitLocalAddonSelection: (
    overrideTier?: TierOptions
  ) => Promise<Quote | Renewal | null>;
};

const isCoverageUpdated = (
  localCoverage: CoverageSelection,
  quote: Quote | Renewal
): boolean => {
  const matchingQuoteCoverage = quote.coverages.find(
    (quoteCoverage) => quoteCoverage.type === localCoverage.type
  );
  return localCoverage.isSelected !== matchingQuoteCoverage?.isSelected;
};

const getCoverageUpdates = (
  localCoverages: CoverageSelection[] | undefined,
  quote: Quote | Renewal
): CoverageSelection[] =>
  localCoverages?.filter((localCoverage) => isCoverageUpdated(localCoverage, quote)) ??
  [];

const isBreakdownUpdateRequired = (
  localBreakdown: LocalBreakdown,
  quote: Quote | Renewal
): boolean =>
  isLocalBreakdownValid(localBreakdown) &&
  (localBreakdown.isSelected !== quote.breakdown.selectedBreakdown.isSelected ||
    localBreakdown.coverLevel !== quote.breakdown.selectedBreakdown.coverLevel);

const isTierUpdateRequired = (
  localTier: TierOptions | null | undefined,
  quote: Quote | Renewal
): boolean => localTier !== undefined && localTier !== quote.tieringInfo?.selectedTier;

/**
 * When a user confirms or declines a coverage or breakdown,
 * we need to make a callback to update the quote's state in DuckCreek
 * DTN-969 - to improve user experience on the page, we instantly update the coverage/breakdown state,
 * and defer the callbacks until we need to commit them.
 * @returns updateLocalCoverage, updateLocalBreakdown, updateLocalTier - make "local" updates
 * @returns commitLocalAddonSelection - makes the callbacks if needed
 * the useQuote() hook is responsible for reading local coverage/breakdown state and passing it to components,
 * and populating paymentDetails with adjusted prices based on coverage and breakdown selection
 */
const useLocalAddonSelection = (
  requestHandler: RequestHandlerType
): LocalAddonSelectionHelper => {
  const [addonSelection, updateAddonSelectionDetails] = useAddonSelection();
  const dispatch = useDispatch();

  // use the underlying state directly, since useQuote() overrides some fields based on addonSelection
  // we need to compare with the real quote to see what changes need to be committed
  const quote = useSelector((state: RootState) => state.quote);

  const updateLocalCoverage = (addon: CoverageSelection): void => {
    if (addonSelection.localCoverages) {
      updateAddonSelectionDetails({
        localCoverages: addonSelection.localCoverages.map((coverage) =>
          coverage.type === addon.type ? addon : coverage
        ),
      });
    }
  };

  const updateLocalBreakdown = (
    isSelected: boolean | null,
    coverLevel?: BreakdownCoverLevel | undefined
  ): void => {
    updateAddonSelectionDetails({
      localBreakdown: {
        isSelected,
        coverLevel: coverLevel ?? null,
      },
    });
  };

  const updateLocalTier = (tier: TierOptions): void => {
    updateAddonSelectionDetails({ localSelectedTier: tier });
  };

  const dispatchQuoteUpdate = (quoteUpdate: Quote | Renewal | undefined): void => {
    if (!quoteUpdate) {
      return;
    }
    if (isRenewal(quoteUpdate)) {
      dispatch({
        type: UPDATE_RENEWAL,
        quote: quoteUpdate,
      });
    } else {
      dispatch({
        type: UPDATE_QUOTE,
        quote: quoteUpdate,
      });
    }
  };

  const updateAddOns = async (
    oldQuote: Quote | Renewal,
    addons: { type: string; isSelected: boolean }[]
  ): Promise<Quote | Renewal | undefined> => {
    return requestHandler(async () => {
      if (isRenewal(oldQuote)) {
        return renewalClient.updateCoverages(oldQuote.policyNumber, addons);
      }
      return quoteClient.updateCoverages(oldQuote.quoteNumber, addons);
    });
  };

  const updateBreakdownAddOn = (
    oldQuote: Quote | Renewal,
    isSelected: boolean,
    coverLevel?: BreakdownCoverLevel
  ): Promise<Quote | Renewal | undefined> => {
    return requestHandler(async () => {
      if (isRenewal(oldQuote)) {
        return renewalClient.updateBreakdownCover(
          oldQuote.policyNumber,
          isSelected,
          coverLevel
        );
      }
      return quoteClient.updateBreakdownCover(
        oldQuote.quoteNumber,
        isSelected,
        coverLevel
      );
    });
  };

  const commitLocalAddonSelectionForNonTieredQuote = async (): Promise<
    Quote | Renewal | null
  > => {
    if (!quote) {
      return null;
    }

    const coverageUpdates = getCoverageUpdates(addonSelection.localCoverages, quote);
    const breakdownUpdateRequired = isBreakdownUpdateRequired(
      addonSelection.localBreakdown,
      quote
    );

    let newQuote: Quote | Renewal | undefined;

    if (coverageUpdates.length) {
      newQuote = await updateAddOns(
        quote,
        coverageUpdates.map((coverage) => ({
          type: coverage.type,
          isSelected: coverage.isSelected ?? false,
        }))
      );

      if (!newQuote) {
        return null;
      }
    }

    if (breakdownUpdateRequired) {
      newQuote = await updateBreakdownAddOn(
        quote,
        !!addonSelection.localBreakdown.isSelected,
        addonSelection.localBreakdown.coverLevel ?? undefined
      );

      if (!newQuote) {
        return null;
      }
    }

    dispatchQuoteUpdate(newQuote);
    return newQuote ?? quote;
  };

  /**
   * @param overrideTier - This is used for when the tier needs to be updated at the same time as
   * committing the add ons
   */
  const commitLocalAddonSelectionForTieredQuote = async (
    overrideTier?: TierOptions
  ): Promise<Quote | Renewal | null> => {
    if (!quote) {
      return null;
    }

    const coverageUpdateRequired = !!getCoverageUpdates(
      addonSelection?.localCoverages,
      quote
    ).length;
    const breakdownUpdateRequired = isBreakdownUpdateRequired(
      addonSelection.localBreakdown,
      quote
    );
    const tierUpdateRequired = isTierUpdateRequired(
      overrideTier ?? addonSelection.localSelectedTier,
      quote
    );

    if (!coverageUpdateRequired && !breakdownUpdateRequired && !tierUpdateRequired) {
      return quote;
    }

    const coverages = addonSelection.localCoverages ?? quote.coverages;
    const breakdown = getLatestBreakdownSelection(
      addonSelection.localBreakdown,
      quote.breakdown.selectedBreakdown
    );
    const tier =
      addonSelection.localSelectedTier === undefined
        ? quote.tieringInfo?.selectedTier
        : addonSelection.localSelectedTier;

    if (!tier) {
      throw new Error('Tier must be selected before updating the quote');
    }

    const tierUpdateRequest: UpdateTierRequest = {
      coverages,
      breakdown,
      tier: overrideTier ?? tier,
    };

    const updatedQuote = await requestHandler(() =>
      isRenewal(quote)
        ? renewalClient.updateTier(quote.policyNumber, tierUpdateRequest)
        : quoteClient.updateTier(quote.quoteNumber, tierUpdateRequest)
    );

    if (updatedQuote) {
      dispatchQuoteUpdate(updatedQuote);
    }
    return updatedQuote ?? null;
  };

  const commitLocalAddonSelection = quote?.tieringInfo
    ? commitLocalAddonSelectionForTieredQuote
    : commitLocalAddonSelectionForNonTieredQuote;

  return {
    updateLocalCoverage,
    updateLocalBreakdown,
    updateLocalTier,
    commitLocalAddonSelection,
  };
};

export default useLocalAddonSelection;
