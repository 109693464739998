import { Image } from '@rsa-digital/evo-shared-components/commonTypes/image';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Layout from 'components/Layout';
import { SessionExpiryOption } from 'components/SessionExpiryWrapper';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { CsAsset, CsMeta } from 'types/contentStack';
import {
  Background,
  ImageContainer,
  LogoContainer,
  ScaledAnimation,
  ScaledLogo,
  StyledHeading,
  StyledSubHeading,
} from './styles';

type InterstitialPageProps = {
  meta: CsMeta;
  heading: string;
  subheading: string;
  tier?: string | null;
} & ComponentProps;

type TierImages = {
  essentials_image: CsAsset;
  standard_image: CsAsset;
  extra_image: CsAsset;
};

type InterstitialPageContent = {
  csInterstitialPage: {
    animation: CsAsset;
    tier_images: TierImages;
  };
};

export const query = graphql`
  query {
    csInterstitialPage {
      animation {
        ...CsAsset
      }
      tier_images {
        essentials_image {
          ...CsAsset
        }
        standard_image {
          ...CsAsset
        }
        extra_image {
          ...CsAsset
        }
      }
    }
  }
`;

const getLogo = (images: TierImages, tier: string): Image | undefined => {
  switch (tier) {
    case 'Tier1':
      return processImageAsset(images.essentials_image);
    case 'Tier2':
      return processImageAsset(images.standard_image);
    case 'Tier3':
      return processImageAsset(images.extra_image);
    default:
      return processImageAsset(images.standard_image);
  }
};

const InterstitialPageBody: React.FC<InterstitialPageProps> = ({
  meta,
  heading,
  subheading,
  tier,
  ...props
}) => {
  const {
    csInterstitialPage: { animation, tier_images },
  } = useStaticQuery<InterstitialPageContent>(query);

  const mainAnimation = processImageAsset(animation);

  const logoImage = getLogo(tier_images, tier ?? 'Tier2');

  return (
    <Layout
      meta={meta}
      pageType="interstitial"
      sessionExpiryOption={SessionExpiryOption.NO_SESSION_EXPIRY}>
      <Background {...componentProps(props)}>
        <StyledHeading>{heading}</StyledHeading>
        <StyledSubHeading>{subheading}</StyledSubHeading>
        {mainAnimation && (
          <ImageContainer>
            <ScaledAnimation animation={mainAnimation} />
          </ImageContainer>
        )}
        <LogoContainer>
          <ScaledLogo src={logoImage?.url} alt={logoImage?.altText} />
        </LogoContainer>
      </Background>
    </Layout>
  );
};

export default InterstitialPageBody;
