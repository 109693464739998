import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import CheckDetailsLoadingPage from 'components/LoadingPages/CheckDetailsLoading';
import { navigatePreservingQuote } from 'helpers/navigation';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { usePageTracking } from 'helpers/usePageTracking';
import { CsMeta } from 'types/contentStack';

type CheckDetailsLoadingProps = {
  data: {
    csInterstitialPage: {
      quote_summary_check_details: {
        meta: CsMeta;
        heading: string;
        subheading: string;
      };
    };
  };
  location: Location & {
    state: {
      tier?: string | null;
    };
  };
};

export const query = graphql`
  query {
    csInterstitialPage {
      quote_summary_check_details {
        meta {
          meta_title
        }
        heading
        subheading
      }
    }
  }
`;

const CheckDetailsLoading: React.FC<CheckDetailsLoadingProps> = ({
  data: {
    csInterstitialPage: { quote_summary_check_details },
  },
  location,
}) => {
  const [tier, setTier] = useState<string | null>();

  useEffect(() => {
    if (location.state.tier) {
      setTier(location.state.tier);
    }
  }, [location]);

  usePageTracking(quote_summary_check_details.meta.meta_title);

  return (
    <CheckDetailsLoadingPage
      checkDetailsLoadingContent={quote_summary_check_details}
      tier={tier}
      moveNext={() =>
        navigatePreservingQuote(
          quoteAndBuyRoutes.checkYourDetails,
          undefined,
          undefined,
          true
        )
      }
    />
  );
};

export default CheckDetailsLoading;
