import { logoLargeWidth } from '@rsa-digital/evo-shared-components/commonStyles/styling';
import Lottie from '@rsa-digital/evo-shared-components/components/Lottie';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled, { css } from 'styled-components';

export const Background = styled.div`
  background: ${colors.core01};
  margin: 0px;
  height: 100vh;
  width: 100vw;
  display: table-cell;
  vertical-align: middle;
`;

const interstitialTextStyling = css`
  margin: 0px;
  text-align: center;
`;

export const StyledHeading = styled.h1`
  padding-bottom: ${spacing(1)};
  ${interstitialTextStyling}
  ${fonts.headingMedium}
  & {
    color: ${colors.neutral08};
  }
`;

export const StyledSubHeading = styled.h1`
  ${interstitialTextStyling}
  ${fonts.paragraphLarge}
  & {
    color: ${colors.neutral08};
  }
`;

const containerStyling = css`
  display: flex;
  justify-content: center;
`;

export const ImageContainer = styled.div`
  margin: ${spacing(6)} 0;
  ${containerStyling}
`;

export const LogoContainer = styled.div`
  ${containerStyling}
  margin-top: ${spacing(3)};
`;

export const ScaledAnimation = styled(Lottie)`
  max-width: 80vw;
`;

export const ScaledLogo = styled.img`
  width: ${logoLargeWidth};
  max-width: 90vw;
`;
