import { AggOptions } from 'pages/quote/aggregator';
import { BreakdownCoverLevel, Preferences, Quote, TierOptions } from 'state/quote/quote';
import apiService from './apiService';
import { FraudCheckRequest } from './quote/fraudCheckRequest';
import { PaymentOption, QuoteRequest } from './quote/quoteRequest';

export type UpdateTierRequest = {
  coverages: { type: string; isSelected: boolean | null }[];
  breakdown: {
    isSelected: boolean | null;
    coverLevel: BreakdownCoverLevel | null;
  };
  tier: TierOptions;
};

type QuoteClient = {
  createQuote: (data: QuoteRequest, refToken?: string) => Promise<Quote>;
  getAggregatorQuote: (ref: string, options: AggOptions) => Promise<Quote>;
  getCurrentQuote: () => Promise<Quote>;
  updateQuote: (quoteNumber: string, data: QuoteRequest) => Promise<Quote>;
  getQuote: (quoteNumber: string) => Promise<Quote>;
  updateExcess: (quoteNumber: string, excess: number) => Promise<Quote>;
  updateCoverages: (
    quoteNumber: string,
    coverages: { type: string; isSelected: boolean }[]
  ) => Promise<Quote>;
  updateBreakdownCover: (
    quoteNumber: string,
    isSelected: boolean,
    coverLevel?: BreakdownCoverLevel
  ) => Promise<Quote>;
  updateTier: (quoteNumber: string, update: UpdateTierRequest) => Promise<Quote>;
  updatePaymentOption: (
    quoteNumber: string,
    paymentOption: PaymentOption
  ) => Promise<Quote>;
  updatePreferences: (quoteNumber: string, preferences: Preferences) => Promise<Quote>;
  accountExists: (quoteNumber: string, email: string) => Promise<void>;
  sendEmail: (quoteNumber: string, email: string) => Promise<void>;
  getQuoteByDeepLink: (deepId: string, postcode: string) => Promise<Quote>;
  antiFraudCheck: (
    quoteNumber: string,
    fraudCheckRequest: FraudCheckRequest
  ) => Promise<void>;
};

const quoteClient: QuoteClient = {
  createQuote: (data: QuoteRequest, refToken?: string) =>
    apiService.post<Quote>('quote', data, refToken ? { refToken } : undefined),
  getAggregatorQuote: (ref, options) =>
    apiService.get<Quote>(`quote/aggregator/${ref}`, options),
  getCurrentQuote: () => apiService.get<Quote>('quote/current'),
  updateQuote: (quoteNumber: string, data: QuoteRequest) =>
    apiService.put<Quote>(`quote/${quoteNumber}`, data),
  getQuote: (quoteNumber) => apiService.get<Quote>(`quote/${quoteNumber}`),
  updateExcess: (quoteNumber: string, excess: number) =>
    apiService.put<Quote>(`quote/${quoteNumber}/excess`, { value: excess }),
  updateCoverages: (
    quoteNumber: string,
    coverages: { type: string; isSelected: boolean }[]
  ) => apiService.put<Quote>(`quote/${quoteNumber}/coverages`, coverages),
  updateBreakdownCover: (
    quoteNumber: string,
    isSelected: boolean,
    coverLevel?: BreakdownCoverLevel
  ) =>
    apiService.put<Quote>(`quote/${quoteNumber}/breakdown`, {
      isSelected,
      coverLevel,
    }),
  updateTier: (quoteNumber, update) =>
    apiService.put<Quote>(`quote/${quoteNumber}/tier`, update),
  updatePaymentOption: (quoteNumber: string, paymentOption: PaymentOption) =>
    apiService.put<Quote>(`quote/${quoteNumber}/paymentpreferences`, {
      paymentOption,
    }),
  updatePreferences: (quoteNumber: string, preferences: Preferences) =>
    apiService.put<Quote>(`quote/${quoteNumber}/preferences`, preferences),
  accountExists: (quoteNumber: string, email: string) =>
    apiService.post<void>(`quote/${quoteNumber}/accountExists`, { email }),
  sendEmail: (quoteNumber: string, email: string) =>
    apiService.post<void>(`quote/${quoteNumber}/sendEmail`, { email }),
  getQuoteByDeepLink: (deepId: string, postcode: string) =>
    apiService.get<Quote>('quote/search', { deepId, postcode }),
  antiFraudCheck: (quoteNumber: string, fraudCheckRequest: FraudCheckRequest) =>
    apiService.post(`quote/${quoteNumber}/fraudCheck`, fraudCheckRequest),
};

export default quoteClient;
